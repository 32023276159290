export default {
    namespaced: true,
    state: {
        mainUserData: {},
        mainUserDevicesData: [],
    },

    getters: {
        getMainUserData(state) {
            return state.mainUserData;
        },
        getmainUserDevicesData(state) {
            return state.mainUserDevicesData;
        },
    },

    actions: {
        clearState({ commit }) {
            commit('CLEAR_STATE');
        },
        setMainUserData({ commit }, newState) {
            commit('SET_MAIN_USER_DATA', newState);
        },
        setMainUserDevicesData({ commit }, newState) {
            commit('SET_MAIN_USER_DEVICES_DATA', newState);
        },
        setMainUserName({ commit }, newName) {
            commit('SET_MAIN_USER_NAME', newName);
        },
        setMainUserProfileImage({ commit }, newProfileImage) {
            commit('SET_MAIN_USER_PROFILE_IMAGE', newProfileImage);
        },
        removeCampaign({ commit }, campaignId) {
            commit('REMOVE_CAMPAIGN', campaignId);
        },
    },

    mutations: {
        CLEAR_STATE(state) {
            state.mainUserData = {};
            state.mainUserDevicesData = [];
        },
        SET_MAIN_USER_DATA(state, newState) {
            state.mainUserData = { ...newState };
        },
        SET_MAIN_USER_DEVICES_DATA(state, newState) {
            state.mainUserDevicesData = [...newState];
        },
        SET_MAIN_USER_NAME(state, newName) {
            state.mainUserData.name = newName;
        },
        SET_MAIN_USER_PROFILE_IMAGE(state, newProfileImage) {
            state.mainUserData.file = newProfileImage;
        },
        REMOVE_CAMPAIGN(state, campaignId) {
            const newState = state.mainUserData;
            const newCampaigns = newState.campaigns.filter(
                (e) => e.campaign.id !== campaignId,
            );
            newState.campaigns = newCampaigns;
            state.mainUserData = { ...newState };
        },
    },
};
