<template>
    <footer class="the-footer w-100">
        <div class="d-flex align-items-center">
            {{ $t('footer.main') }}
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'TheFooter',
    };
</script>
