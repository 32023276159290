<template>
    <app-overlay class="loading-overlay" :show-overlay="showOverlay">
        <app-spinner></app-spinner>
    </app-overlay>
</template>

<script>
    import AppOverlay from './AppOverlay.vue';
    import AppSpinner from './AppSpinner.vue';

    export default {
        components: { AppOverlay, AppSpinner },
        name: 'AppOverlayLoading',
        props: {
            showOverlay: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
