import enGB from './en-GB.json';
import esES from './es-ES.json';

export default {
    'en-GB': {
        name: 'English',
        translation: enGB,
    },
    'es-ES': {
        name: 'Spanish',
        translation: esES,
    },
};
