<template>
    <b-overlay class="app-overlay" :show="showOverlay" no-wrap>
        <template #overlay>
            <slot></slot>
        </template>
    </b-overlay>
</template>

<script>
    export default {
        name: 'AppOverlay',
        props: {
            showOverlay: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
