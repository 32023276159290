<template>
    <b-nav-item-dropdown
        :text="text"
        class="app-dropdown"
        :dropup="dropup"
        :class="dropdownClass"
        :right="right"
        @hide="show = false"
        @show="show = true"
    >
        <template v-slot:button-content>
            <slot name="button-template"></slot>
        </template>

        <transition name="slideDown" leave-active-class="fade">
            <ul v-if="show" class="dropdown-container dropdown-menu show">
                <slot name="dropdown-items"></slot>
            </ul>
        </transition>
    </b-nav-item-dropdown>
</template>

<script>
    export default {
        name: 'AppDropdown',
        props: {
            text: {
                type: String,
                default: '',
            },
            dropdownClass: {
                type: String,
                default: '',
            },
            right: {
                type: Boolean,
                default: false,
            },
            dropup: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                show: false,
            };
        },
    };
</script>
