<template>
    <div class="home">
        <device-watch-information />
    </div>
</template>

<script>
    import DeviceWatchInformation from '@/components/device/deviceWatchInformation/DeviceWatchInformation.vue';

    export default {
        name: 'Home',
        components: {
            DeviceWatchInformation,
        },
    };
</script>
