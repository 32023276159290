export default {
    namespaced: true,
    state: {
        products: {},
    },

    getters: {
        getProducts(state) {
            return state.products;
        },
    },

    actions: {
        setProducts({ commit }, products) {
            commit('SET_PRODUCTS', products);
        },
    },

    mutations: {
        SET_PRODUCTS(state, products) {
            state.products = { ...products };
        },
    },
};
