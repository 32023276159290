import gql from 'graphql-tag';

export const LOGIN_ADMIN = gql`
    mutation signInWithEmailOrPhone(
        $emailAddress: String
        $countryPhoneNumber: String
        $phoneNumber: String
        $password: String!
        $userLang: String!
        $timeZone: String!
        $client: ClientType!
    ) {
        signInWithEmailOrPhone(
            emailAddress: $emailAddress
            countryPhoneNumber: $countryPhoneNumber
            phoneNumber: $phoneNumber
            password: $password
            userLang: $userLang
            timeZone: $timeZone
            client: $client
        ) {
            token
            user {
                id
                name
                xcoin
                countryCode
                countryPhoneCode
                mobilePhoneNumberNational
                extra
                premiumFlags {
                    goLearn
                    isPremiumAvailable
                    isPodcastAvailable
                }
                children {
                    approval
                    flag
                    ward {
                        id
                        name
                        xcoin
                        countryPhoneCode
                        mobilePhoneNumberNational
                        extra
                        premiumFlags {
                            goLearn
                            isPremiumAvailable
                            isPodcastAvailable
                        }
                    }
                }
            }
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation userM(
        $countryPhoneNumber: String!
        $phoneNumber: String!
        $verifyCode: String!
        $password: String!
    ) {
        resetPassword(
            countryPhoneNumber: $countryPhoneNumber
            phoneNumber: $phoneNumber
            verifyCode: $verifyCode
            password: $password
        ) {
            alreadyRegistered
            resetSuccess
        }
    }
`;

export const UPDATE_MY_INFO = gql`
    mutation userM(
        $uid: String!
        $name: String
        $oldPassword: String
        $newPassword: String
        $profileId: String
    ) {
        updateUserInfo(
            uid: $uid
            name: $name
            oldPassword: $oldPassword
            newPassword: $newPassword
            profileId: $profileId
        ) {
            name
            file {
                id
                name
                orig {
                    path
                    urlPath
                    urlPathS3
                }
            }
        }
    }
`;

export const DELETE_USER = gql`
    mutation userM($uid: String) {
        deleteUser(uid: $uid) {
            uid
        }
    }
`;

export const UPDATE_USER_INFO = gql`
    mutation updateUser(
        $name: String
        $uid: String!
        $emailAddress: String
        $profileId: String
        $avatarId: String
    ) {
        updateUserInfo(
            name: $name
            uid: $uid
            emailAddress: $emailAddress
            profileId: $profileId
            avatarId: $avatarId
        ) {
            name
            emailAddress
            file {
                id
                name
                orig {
                    path
                    urlPath
                    urlPathS3
                }
            }
        }
    }
`;
