import Vue from 'vue';
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import getBrowserLanguage from '@/util/i18n/get-browser-language';
import isSupportedLanguage from '@/util/i18n/is-supported-language';
import store from '@/store';
import supportedLanguages from './languages';

Vue.use(VueI18Next);

function getStartingLanguage() {
    const preferredLanguage = store.getters['app/getLanguage'];
    if (preferredLanguage !== '') {
        return preferredLanguage;
    }

    let startingLanguage = getBrowserLanguage();
    if (!isSupportedLanguage(startingLanguage)) {
        startingLanguage = 'en-GB';
    }

    store.dispatch('app/setLanguage', startingLanguage);
    return startingLanguage;
}

i18next.init({
    lng: getStartingLanguage(),
    fallbackLng: 'en-GB',
    supportedLngs: Object.keys(supportedLanguages),
    resources: supportedLanguages,
});

export default new VueI18Next(i18next);
