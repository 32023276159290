<template>
    <div class="app-spinner text-center p-4 bg-primary text-light rounded">
        <b-spinner variant="light" label="Spinning"></b-spinner>
        <div>{{ $t('processing') }}</div>
    </div>
</template>

<script>
    export default {
        name: 'AppSpinner',
    };
</script>
