export default {
    namespaced: true,
    state: {
        language: localStorage.getItem('preferred-language') || '',
    },

    getters: {
        getLanguage(state) {
            return state.language;
        },
    },

    actions: {
        setLanguage({ commit }, language) {
            commit('SET_LANGUAGE', language);
        },
    },

    mutations: {
        SET_LANGUAGE(state, language) {
            state.language = language;
        },
    },
};
