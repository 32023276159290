import Vue from 'vue';
import VueRouter from 'vue-router';
import {
    seamlessLogin,
    clearLoginData,
    isLoggedIn,
} from '@/util/graphql/auth-utils';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { requiresAuth: true },
    },
    {
        path: '/maps',
        name: 'Maps',
        component: () => import('../views/Maps.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/devices/watch-info/:uid/:swkey',
        name: 'watch-info',
        component: () =>
            import(
                '../components/device/deviceWatchInformation/DeviceWatchInformation.vue'
            ),
        meta: { requiresAuth: true },
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import('../views/Account.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        async beforeEnter(to, from, next) {
            const isUserLoggedIn = isLoggedIn();
            if (isUserLoggedIn) {
                next({
                    path: '/',
                });
            } else {
                next();
            }
        },
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: () => import('../views/ForgotPassword.vue'),
        async beforeEnter(to, from, next) {
            const isUserLoggedIn = isLoggedIn();
            if (isUserLoggedIn) {
                next({
                    path: '/',
                });
            } else {
                next();
            }
        },
    },
    {
        path: '/resetPassword/:token',
        name: 'resetPassword',
        component: () => import('../views/ResetPassword.vue'),
        async beforeEnter(to, from, next) {
            const isUserLoggedIn = isLoggedIn();
            if (isUserLoggedIn) {
                next({
                    path: '/',
                });
            } else {
                next();
            }
        },
    },
];

const router = new VueRouter({
    routes,
    mode: 'history',
});

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    try {
        const token = await seamlessLogin();

        if (requiresAuth) {
            // Check if the user is logged in
            if (token === '') {
                clearLoginData();

                next({
                    path: '/login',
                    query: { redirect: to.fullPath },
                });
                return;
            }

            next();
            return;
        }

        next();
    } catch (error) {
        next({
            path: '/login',
            query: { redirect: to.fullPath },
        });
    }
});

export default router;
