import gql from 'graphql-tag';

export const READ_MY_INFO = gql`
    query userQ {
        readMyInfo {
            id
            userId
            name
            nickname
            lastLoginDate
            file {
                id
                orig {
                    urlPathS3
                }
            }
            roles {
                id
                name
                type
            }
            children {
                id
                ward {
                    id
                    userId
                    name
                    mobilePhoneNumberNational: phoneNumber
                    countryPhoneCode
                    file {
                        id
                    }
                }
                guardian {
                    id
                    userId
                    file {
                        id
                    }
                }
                approval
            }
        }
    }
`;

export const GET_COUNTRIES = gql`
    query countryQ {
        countries {
            id
            code
            name
            status
            listOrder
            create
        }
    }
`;

export const GET_SUPPORTED_LANGS = gql`
    query langQ {
        supportLangs {
            id
            name
            langKey
            description
            create
        }
    }
`;

export const SEARCH_USER = gql`
    query searchUserByPhoneNumber($phoneNumber: String!) {
        searchUserByPhoneNumber(phoneNumber: $phoneNumber) {
            user {
                id
                userId
                name
                countryPhoneCode
                phoneNumber
            }
        }
    }
`;
export const SEARCH_USER_WITH_EMAIL = gql`
    query t($emailAddress: String!) {
        searchUserByEmail(emailAddress: $emailAddress) {
            user {
                id
                userId
            }
        }
    }
`;

export const SEARCH_USER_DATA = gql`
    query getUserById($uid: String!) {
        getUserById(uid: $uid) {
            user {
                id
                userId
                name
                countryCode
                countryPhoneCode
                phoneNumber
                emailAddress
                mobilePhoneNumber
                lastLoginDate
                create
                xcoin
                extra
                totalStep
                file {
                    id
                    orig {
                        urlPathS3
                    }
                }
                children {
                    id
                    ward {
                        id
                        userId
                        name
                        countryCode
                        countryPhoneCode
                        phoneNumber
                        mobilePhoneNumber
                        lastLoginDate
                        xcoin

                        file {
                            id
                        }
                    }
                }
            }
            watches {
                id
                name
                groupName
                countryPhoneCode
                phoneNumber
                onlineStatus
                status
                activated
                create
                update
                brand
                qrCode
                os
                swKey
                osName
                osVersion
                extra
                user {
                    name
                    id
                    extra
                    file {
                        id
                        orig {
                            urlPathS3
                        }
                    }
                }
                vendor {
                    id
                    name
                    status
                }
            }
        }
    }
`;

export const GET_WATCH_BLOCK_HISTORY = gql`
    query getWatchBlockHistory($imei: String!) {
        getWatchBlockHistory(imei: $imei) {
            id
            imei
            status
            reason
            blockedBy
            blockedOn
        }
    }
`;

export const GET_WATCH_LOG = gql`
    query getWatchLog($imei: String!, $limit: Int, $offset: Int) {
        getWatchLog(imei: $imei, limit: $limit, offset: $offset) {
            count
            data {
                id
                imei
                name
                zipFile
                reason
                actionTaken
                uploadDate
            }
        }
    }
`;

export const GET_DEVICE_WATCH_LOG_BY_GUARDIAN = gql`
    query getWatchLogByGuardian($offset: Int, $limit: Int) {
        getWatchLogByGuardian(offset: $offset, limit: $limit) {
            count
            data {
                id
                imei
                name
                zipFile
                reason
                actionTaken
                uploadDate
            }
        }
    }
`;

export const GET_DEVICE_LOCATION_HISTORY = gql`
    query getLocationHistory($uid: String!) {
        getLocationHistory(uid: $uid) {
            day0 {
                time
                reportTime
                lng
                lat
                radius
                locateType
                br
                step
                distance
                safezone
                extra
            }
            day1 {
                time
                reportTime
                lng
                lat
                radius
                locateType
                br
                step
                distance
                safezone
                extra
            }
            day2 {
                time
                reportTime
                lng
                lat
                radius
                locateType
                br
                step
                distance
                safezone
                extra
            }
            day3 {
                time
                reportTime
                lng
                lat
                radius
                locateType
                br
                step
                distance
                safezone
                extra
            }
        }
    }
`;

export const SEARCH_DEVICE_BY_PHONE = gql`
    query searchDeviceByPhoneNumber($phoneNumber: String!) {
        searchDeviceByPhoneNumber(phoneNumber: $phoneNumber) {
            watch {
                id
            }
        }
    }
`;

export const GET_WATCHES = gql`
    query watchQ {
        watches {
            id
            name
            groupName
            countryPhoneCode
            phoneNumber
            onlineStatus
            status
            activated
            create
            update
            brand
            qrCode
            swKey
            os
            osName
            osVersion
            vendor {
                id
                name
                status
            }
        }
    }
`;
