import store from '@/store';
import { getUserInfo } from '@/api/graphql/requests';

export function isLoggedIn() {
    return store.getters['auth/isLoggedIn'];
}

export function clearLoginData() {
    store.dispatch('auth/setLoginData', {
        user: {},
        token: '',
        isLoggedIn: false,
    });
}

export async function seamlessLogin() {
    const token = store.getters['auth/getAuthToken'];
    const isUserLoggedIn = isLoggedIn();

    if (token !== '' && !isUserLoggedIn) {
        // User has token so fetch user info and login
        try {
            const user = await getUserInfo();

            store.dispatch('auth/setLoginData', {
                user,
                token,
                isLoggedIn: true,
            });
        } catch (error) {
            clearLoginData();
            throw new Error('Authentication Failed');
        }
    }

    return token;
}
