<template>
    <div class="device-information-item">
        <div class="title">{{ title }}</div>
        <div class="info__row">
            <div class="info-section">
                <div class="info__title">Id :</div>
                <div class="info__value">
                    {{ item.id }}
                </div>
            </div>

            <div class="info-section">
                <div class="info__title">Reason :</div>
                <div class="info__value">
                    {{ item.reason }}
                </div>
            </div>
        </div>
        <div class="info__row">
            <div class="info-section">
                <div class="info__title">Imei:</div>
                <div class="info__value">
                    {{ item.imei }}
                </div>
            </div>

            <div class="info-section">
                <div class="info__title">Upload at:</div>
                <div class="info__value">
                    {{ item.uploadDate }}
                </div>
            </div>
            <div class="info-section">
                <div class="info__title">Download file:</div>
                <div v-show="item.zipFile !== ''" class="info__value">
                    <a
                        style="color: red; cursor: pointer"
                        @click="() => handleDownload(item.zipFile, item)"
                        >Download</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DeviceResetInformationItem',
        props: {
            item: {
                type: Object,
                default: () => {},
            },
            title: {
                type: String,
                default: '',
            },
        },
        methods: {
            handleDownload(file, item) {
                const name = `watch-log-${item.imei.slice(
                    -4,
                )}_${item.uploadDate.replace(/\s/g, '_')}`;
                fetch(file)
                    .then((response) => response.blob())
                    .then((blob) => {
                        const link = document.createElement('a');
                        const url = URL.createObjectURL(blob);
                        link.href = url;
                        link.setAttribute('download', name);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(url);
                    })
                    .catch((error) => {
                        console.error('Error downloading the file:', error);
                    });
            },
        },
        computed: {},
    };
</script>
