export default {
    namespaced: true,
    state: {
        auction: [],
    },

    getters: {
        getAuctionItems(state) {
            return state.auction;
        },
    },

    actions: {
        setAuction({ commit }, items) {
            commit('SET_AUCTION', items);
        },
    },

    mutations: {
        async SET_AUCTION(state, items) {
            state.auction = [...items];
        },
    },
};
