import app from './app';
import auth from './auth';
import user from './user';
import users from './users';
import device from './device';
import products from './products';
import auction from './auction';

export default {
    app,
    auth,
    user,
    users,
    device,
    auction,
    products,
};
