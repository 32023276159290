export default {
    namespaced: true,
    state: {
        id: '',
        name: '',
        nickname: '',
        lastLoginDate: '',
        roles: {},
        children: {},
        file: {},
    },

    getters: {
        getUser(state) {
            return state;
        },
        getName(state) {
            return state.name;
        },
        getNickname(state) {
            return state.nickname;
        },
        getLastLoginDate(state) {
            return state.lastLoginDate;
        },
        getRoles(state) {
            return state.roles;
        },
        getChildren(state) {
            return state.children;
        },
        getFile(state) {
            return state.file;
        },
    },

    actions: {
        setUser({ commit }, user) {
            commit('SET_USER', user);
        },
        setName({ commit }, user) {
            commit('SET_NAME', user);
        },
    },

    mutations: {
        SET_USER(state, user) {
            Object.assign(state, user);
        },
        SET_NAME(state, name) {
            state.name = name;
        },
    },
};
