<template>
    <b-navbar class="app-account-menu" type="light">
        <b-navbar-nav>
            <app-dropdown
                :dropup="true"
                v-if="isLoggedIn"
                dropdown-class="user-dropdown"
            >
                <template #button-template>
                    <div class="app-account-menu__picture-container">
                        <img
                            class="app-account-menu__picture rounded-circle"
                            :src="
                                userPictureFile.orig &&
                                userPictureFile.orig.urlPathS3
                                    ? userPictureFile.orig.urlPathS3
                                    : getDefaultPicture()
                            "
                        />
                    </div>
                    <div class="dropdown-text">{{ name ? name : 'user' }}</div>

                    <b-icon icon="gear-fill"></b-icon>
                </template>

                <template #dropdown-items>
                    <!-- <b-dropdown-item to="/account">{{
                        $t('account')
                    }}</b-dropdown-item> -->
                    <b-dropdown-item @click="handleLogout">{{
                        $t('logout')
                    }}</b-dropdown-item>
                </template>
            </app-dropdown>
        </b-navbar-nav>
    </b-navbar>
</template>

<script>
    import { logout } from '@/api/graphql/requests';
    import supportedLanguages from '@/plugins/i18n/languages';
    import { mapActions, mapGetters } from 'vuex';
    import AppDropdown from './AppDropdown.vue';

    export default {
        name: 'AppAccountMenu',
        components: { AppDropdown },
        data() {
            return {
                supportedLanguages,
            };
        },
        computed: {
            ...mapGetters({
                name: 'user/getName',
                userPictureFile: 'user/getFile',
                isLoggedIn: 'auth/isLoggedIn',
                currentLang: 'app/getLanguage',
            }),
            iconFlag() {
                const flags = require.context('@/assets/flags', false, /\.png$/);
                return flags(`./${this.currentLang}.png`);
            },
        },
        methods: {
            ...mapActions({
                setLanguage: 'app/setLanguage',
            }),
            changeLanguage(lang) {
                this.$i18n.i18next.changeLanguage(lang);
                localStorage.setItem('preferred-language', lang);
                this.setLanguage(lang);
            },
            async handleLogout() {
                await logout();

                this.$router.push('/login');
            },
            getDefaultPicture() {
                const defaultPicture = require.context(
                    '@/assets/user',
                    false,
                    /\.png$/,
                );
                return defaultPicture('./default-profile-pic.png');
            },
        },
    };
</script>
