<template>
    <div id="app" class="app-container">
        <the-sidebar v-if="isLoggedIn"></the-sidebar>
        <div class="app-body" :class="{ 'logged-in': isLoggedIn }">
            <router-view class="app-view" />
            <the-footer></the-footer>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import TheFooter from './components/sections/TheFooter.vue';
    import TheSidebar from './components/sections/TheSidebar.vue';

    export default {
        components: { TheFooter, TheSidebar },
        computed: {
            ...mapGetters({
                isLoggedIn: 'auth/isLoggedIn',
            }),
        },
    };
</script>
