export default {
    namespaced: true,
    state: {
        isLoggedIn: false,
        token: localStorage.getItem('apollo-token') || '',
    },

    getters: {
        isLoggedIn(state) {
            return state.isLoggedIn;
        },
        getAuthToken(state) {
            return state.token;
        },
    },

    actions: {
        setLoginData({ commit, dispatch }, loginInfo) {
            dispatch('user/setUser', loginInfo.user, { root: true });
            commit('SET_TOKEN', loginInfo.token);
            commit('SET_IS_LOGGED_IN', loginInfo.isLoggedIn);
        },
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_IS_LOGGED_IN(state, isLoggedIn) {
            state.isLoggedIn = isLoggedIn;
        },
    },
};
