<template>
    <div class="watch-information app-view">
        <b-container>
            <h3 class="goplay-store__title">Watch log ({{ totalCount }})</h3>

            <div>
                <b-tabs
                    content-class="mt-3"
                    pills
                    class="watch-infomation__tabs"
                >
                    <device-watch-information-item
                        v-for="(data, index) in resetData"
                        :key="index"
                        :item="data"
                        :title="`#${index + 1}`"
                    />
                    <p class="watch-information__no-data" v-if="noData">
                        No data was found
                    </p>
                </b-tabs>
            </div>
            <b-pagination
                v-model="currentPage"
                pills
                class="my-3"
                :total-rows="getRows"
                :per-page="20"
                size="md"
                align="center"
                @change="handlePageChanges"
            ></b-pagination>
        </b-container>
        <app-overlay-loading
            :show-overlay="processingSearch"
        ></app-overlay-loading>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { getDeviceWatchLogByGuardian } from '@/api/graphql/requests';
    import AppOverlayLoading from '../../ui/AppOverlayLoading.vue';
    import DeviceWatchInformationItem from './DeviceWatchInformationItem.vue';

    export default {
        name: 'DeviceWatchInformation',
        components: {
            AppOverlayLoading,
            DeviceWatchInformationItem,
        },
        data() {
            return {
                details: {},
                error: false,
                resetData: [],
                blockData: [],
                noData: false,
                processingSearch: null,
                isDeviceReset: false,
                totalCount: 0,
                currentPage: 1,
            };
        },
        methods: {
            async fetchData(limit, offset) {
                this.error = false;
                this.noData = false;
                this.processingSearch = true;
                const resetWatchInfo = await getDeviceWatchLogByGuardian(
                    limit,
                    offset,
                );
                this.totalCount = resetWatchInfo.count;
                console.log(resetWatchInfo);
                if (resetWatchInfo.data && resetWatchInfo.data.length > 0) {
                    await resetWatchInfo.data.forEach((n, index) => {
                        if (typeof n.uploadDate === 'number') {
                            const date = new Date(n.uploadDate * 1000);
                            const [dateString, timeString] = date
                                .toLocaleString()
                                .split(',');
                            resetWatchInfo.data[
                                index
                            ].uploadDate = `${dateString} ${timeString}`;
                        }
                    });
                    this.resetData = resetWatchInfo.data;
                    this.processingSearch = false;
                    return;
                }
                this.noData = true;
                this.processingSearch = false;
            },
            handlePageChanges(page) {
                this.fetchData(20, (page - 1) * 20);
            },
        },
        computed: {
            ...mapGetters({
                deviceData: 'device/getDeviceData',
            }),
            getRows() {
                return this.totalCount;
            },
        },
        created() {
            this.fetchData(20, 0);
        },
    };
</script>
