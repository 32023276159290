import Vue from 'vue';
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client';
import { setContext } from 'apollo-link-context';
import store from '@/store';

// Name of the localStorage item
export const AUTH_TOKEN = 'apollo-token';
const HEADER_SIGNATURE = '27ed7670379511eab4a0f367f8eb1312';
const OPEN_TOKEN = '63fa1d10289711ea80b5992f808043b2';

// Http endpoint
const httpEndpoint =
    process.env.VUE_APP_GRAPHQL_HTTP || `${process.env.VUE_APP_URL}/api`;

// Files URL root
export const filesRoot =
    process.env.VUE_APP_FILES_ROOT ||
    httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));
Vue.prototype.$filesRoot = filesRoot;

const authLink = setContext(async () => {
    // Initialise with the non authenticated headers
    let backDoorAuth = `Open ${OPEN_TOKEN}:${HEADER_SIGNATURE}`;
    let authorization = '';

    // Get the authentication token from Vuex Auth module if it exists and update headers
    const token = store.getters['auth/getAuthToken'];
    if (token !== '') {
        backDoorAuth = `Bearer ${token}:${HEADER_SIGNATURE}`;
        authorization = backDoorAuth;
    }

    return {
        headers: {
            'H-BackDoor-Authorization': backDoorAuth,
            'H-Tid': '1607582016652',
            authorization,
        },
    };
});

// Config
const defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint,
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
    wsEndpoint: null,
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,

    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    link: authLink,

    // Override default cache
    // cache: myCache

    // Override the way the Authorization header is set
    // getAuth: (tokenName) => ...

    // Additional ApolloClient options
    // apollo: { ... }

    // Client local data (see apollo-link-state)
    // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Create apollo client
export const { apolloClient } = createApolloClient({
    ...defaultOptions,
});

// Manually call this when user log in
export async function onLogin(token) {
    if (typeof localStorage !== 'undefined' && token) {
        localStorage.setItem(AUTH_TOKEN, token);
    }

    try {
        await apolloClient.resetStore();
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(
            '%cError on cache reset (login)',
            'color: orange;',
            e.message,
        );
    }
}

// Manually call this when user log out
export async function onLogout() {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(AUTH_TOKEN);
    }

    try {
        await apolloClient.resetStore();
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(
            '%cError on cache reset (logout)',
            'color: orange;',
            e.message,
        );
    }
}

export const gqlQuery = async (query, variables = {}) =>
    apolloClient.query({
        query,
        variables,
        fetchPolicy: 'network-only',
    });

export const gqlMutation = async (mutation, variables = {}) =>
    apolloClient.mutate({
        mutation,
        variables,
    });
