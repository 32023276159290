<template>
    <div class="the-sidebar">
        <b-navbar-brand to="/" class="app-logo">
            <img src="@/assets/xplora-logo-red.png" alt="Xplora Logo" />
        </b-navbar-brand>

        <b-navbar-nav>
            <app-nav-item
                location="/"
                icon="house-door-fill"
                :text="$t('home')"
            ></app-nav-item>
            <app-nav-item
                location="/maps"
                icon="pin-map"
                text="Maps"
            ></app-nav-item>
        </b-navbar-nav>

        <app-account-menu></app-account-menu>
    </div>
</template>

<script>
    import AppNavItem from '../ui/AppNavItem.vue';
    import AppAccountMenu from '../ui/AppAccountMenu.vue';

    export default {
        components: { AppNavItem, AppAccountMenu },
        name: 'TheSidebar',
    };
</script>
