<template>
    <b-nav-item :to="location" class="app-nav-item" :class="navItemClass">
        <div class="app-nav-item__icon-container">
            <b-icon class="app-nav-item__icon" :icon="icon"></b-icon>
        </div>
        <div class="app-nav-item__text">{{ text }}</div>
    </b-nav-item>
</template>

<script>
    export default {
        name: 'AppNavItem',
        data() {
            return {
                navItemClass: '',
            };
        },
        props: {
            location: {
                type: String,
                default: '/',
            },
            icon: {
                type: String,
                default: '',
            },
            text: {
                type: String,
                default: '',
            },
        },
    };
</script>
