export default {
    namespaced: true,
    state: {
        deviceData: {},
    },

    getters: {
        getDeviceData(state) {
            return state.deviceData;
        },
    },

    actions: {
        clearState({ commit }) {
            commit('CLEAR_STATE');
        },
        setDeviceData({ commit }, newState) {
            commit('SET_DEVICE_DATA', newState);
        },
        addNewContact({ commit }, newContact) {
            commit('ADD_NEW_CONTACT', newContact);
        },
        removeContact({ commit }, contact) {
            commit('REMOVE_CONTACT', contact);
        },
        removeCampaign({ commit }, campaignId) {
            commit('REMOVE_CAMPAIGN', campaignId);
        },
        setContactGuardian({ commit }, { contact, guardianType }) {
            commit('SET_CONTACT_GUARDIAN', { contact, guardianType });
        },
    },

    mutations: {
        CLEAR_STATE(state) {
            state.deviceData = {};
        },
        SET_DEVICE_DATA(state, newState) {
            state.deviceData = newState;
        },
        ADD_NEW_CONTACT(state, newContact) {
            const newState = state.deviceData;
            newState.contacts.push(newContact);
            state.deviceData = { ...newState };
        },
        REMOVE_CONTACT(state, contact) {
            const newState = state.deviceData;
            const newContact = newState.contacts.filter(
                (e) => e.id !== contact.id,
            );
            newState.contacts = newContact;
            state.deviceData = { ...newState };
        },
        REMOVE_CAMPAIGN(state, campaignId) {
            const newState = state.deviceData;
            const newCampaigns = newState.campaigns.filter(
                (e) => e.campaign.id !== campaignId,
            );
            newState.campaigns = newCampaigns;
            state.deviceData = { ...newState };
        },
        SET_CONTACT_GUARDIAN(state, { contact, guardianType }) {
            const newState = { ...state.deviceData };
            const index = newState.contacts.findIndex(
                (e) => e.id === contact.id,
            );

            if (index !== -1) {
                newState.contacts[index].guardianType = guardianType;
            }
            state.deviceData = newState;
        },
    },
};
