// import md5 from 'md5';
import store from '@/store';

import { LOGIN_ADMIN, UPDATE_MY_INFO, UPDATE_USER_INFO } from './mutations';
import {
    GET_WATCH_LOG,
    GET_DEVICE_WATCH_LOG_BY_GUARDIAN,
    GET_DEVICE_LOCATION_HISTORY,
    SEARCH_DEVICE_BY_PHONE,
    SEARCH_USER_DATA,
    READ_MY_INFO,
    GET_WATCHES,
} from './queries';
import {
    gqlQuery,
    gqlMutation,
    onLogin,
    onLogout,
} from '../../plugins/vue-apollo/apolloClient';

export async function apiLogout() {
    // Remove the token on Vue Apollo
    await onLogout();
}

export async function logout() {
    await apiLogout();

    store.dispatch('auth/setLoginData', {
        user: {},
        token: '',
        isLoggedIn: false,
    });
}

export async function apiLogin(loginData) {
    try {
        const loginResult = await gqlMutation(LOGIN_ADMIN, loginData);
        const { signInWithEmailOrPhone } = loginResult.data;

        // Set the token on Vue Apollo
        await onLogin(signInWithEmailOrPhone.token);

        return signInWithEmailOrPhone;
    } catch (error) {
        throw new Error(error.message);
    }
}

export async function searchUserData(id) {
    try {
        const response = await gqlQuery(SEARCH_USER_DATA, { uid: id });
        return response.data.getUserById;
    } catch (error) {
        await apiLogout();
        throw new Error(error.message);
    }
}

export async function login(loginData) {
    const loginInfo = await apiLogin(loginData);
    const { user, token } = loginInfo;

    console.log('loginInfo', user.id);
    // const userInfo = await searchUserData(user.id);

    // console.log('userInfo', userInfo);

    store.dispatch('auth/setLoginData', {
        user,
        token,
        isLoggedIn: true,
    });
}

export async function getDeviceLog(imei, limit, offset) {
    try {
        const res = await gqlQuery(GET_WATCH_LOG, {
            imei,
            limit,
            offset,
        });
        return res.data.getWatchLog;
    } catch (error) {
        throw new Error(error.message);
    }
}

export async function updateMyInfo(info) {
    try {
        const updatedInfo = await gqlMutation(UPDATE_MY_INFO, info);
        store.dispatch('user/setUser', updatedInfo.data.updateUserInfo);

        return true;
    } catch (error) {
        return error.message;
    }
}

export async function updateUserInfo(info) {
    try {
        return await gqlMutation(UPDATE_USER_INFO, info);
    } catch (error) {
        return error.message;
    }
}

export async function getDeviceWatchLogByGuardian(limit, offset) {
    try {
        const deviceWatchLog = await gqlQuery(
            GET_DEVICE_WATCH_LOG_BY_GUARDIAN,
            { limit, offset },
        );
        return deviceWatchLog.data.getWatchLogByGuardian;
    } catch (error) {
        return error;
    }
}
export async function getDeviceLocationHistory(watchId) {
    try {
        const locationHistory = await gqlQuery(GET_DEVICE_LOCATION_HISTORY, {
            uid: watchId,
        });

        return locationHistory.data.getLocationHistory;
    } catch (error) {
        return error;
    }
}

export async function searchDevice(value) {
    try {
        const phoneNumber = value.trim();
        const deviceData = await gqlQuery(SEARCH_DEVICE_BY_PHONE, {
            phoneNumber,
        });
        const deviceInfo = deviceData.data.searchDeviceByPhoneNumber;
        return deviceInfo;
    } catch (error) {
        return error.message;
    }
}

export async function getUserInfo() {
    try {
        const response = await gqlQuery(READ_MY_INFO);
        return response.data.readMyInfo;
    } catch (error) {
        await apiLogout();
        throw new Error(error.message);
    }
}

export async function getWatches() {
    try {
        const response = await gqlQuery(GET_WATCHES);
        console.log('response', response);
        return response.data;
    } catch (error) {
        await apiLogout();
        throw new Error(error.message);
    }
}
