import Vue from 'vue';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import createProvider from '@/plugins/vue-apollo';
import VueTelInput from 'vue-tel-input';
import VueHtml2Canvas from 'vue-html2canvas';
import VueQRCodeComponent from 'vue-qrcode-component';
import VueCryptojs from 'vue-cryptojs';
import * as VueGoogleMaps from 'vue2-google-maps';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './plugins/i18n';

// Import global styling
import './style/global.scss';

// Import animations
import 'vue2-animate/dist/vue2-animate.min.css';

// Install lodash
Vue.use(VueLodash, { lodash });

// Install BootstrapVue
Vue.use(BootstrapVue);
// Install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// Install Vue Tel Input
Vue.use(VueTelInput);

// Install vue-cryptoJS
Vue.use(VueCryptojs);

// instal VueHtml2Canvas;
Vue.use(VueHtml2Canvas);

// install vue-google-map
Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        libraries: 'places',
    },
});
Vue.component('qr-code', VueQRCodeComponent);

Vue.config.productionTip = false;

document.title = `${process.env.VUE_APP_TITLE} - Administrative System`;

new Vue({
    router,
    store,
    i18n,
    apolloProvider: createProvider(),
    render: (h) => h(App),
}).$mount('#app');
